import React from "react";
import { useState, useEffect, useRef } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Modal } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Img from "gatsby-image";
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({

  root:{
    width:'100%',
    height:'100%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  top:{
    height:'20px',
  },
  bottom:{
    height:'20px',
  },
  outerCenter:{
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
  },
  left:{
    marginLeft:20,
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
  },
  right: {
    marginRight:20,
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
  },
  center: {
    display: 'flex',
    flexGrow: 1,
  },
}));


const InsideModal = (props) => {
  const classes = useStyles();
  const imageAreaRef = useRef(null)
  const [left, setLeft] = useState(100);
  const [top, setTop] = useState(100);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const update = () => {
    const rect = imageAreaRef.current.getBoundingClientRect();

    const imgAspect = props.images[props.current].aspectRatio;
    const viewAspect = rect.width / rect.height;

    console.log("update");
    console.log("rect", rect.x, rect.y, rect.width, rect.height);
    console.log("aspect", imgAspect, viewAspect);

    let width = 0;
    let height = 0;
    if (imgAspect > viewAspect){
      // Width is limiting factor
      width = rect.width;
      height = width / imgAspect;
    }else{
      // Height is limiting factor
      height = rect.height;
      width = height * imgAspect;
    }

    const x = rect.x + (rect.width - width) / 2;
    const y = rect.y + (rect.height - height) / 2;

    console.log("solution", x, y, width, height);

    setLeft(x)
    setTop(y)
    setWidth(width)
    setHeight(height)
  }

  useEffect(() => {
    update();
    window.addEventListener("resize", update);
    return () => window.removeEventListener("resize", update);
  });

  const onLeft = (e) => {
    e.stopPropagation();
    let next = props.current - 1 ;
    if (next < 0){
      next = props.images.length - 1;
    }
    props.onLeft(next);
  }

  const onRight = (e) => {
    e.stopPropagation();
    let next = props.current + 1 ;
    if (next >= props.images.length){
      next = 0;
    }
    props.onRight(next);
  }

  console.log("Render", width, height)

  return (
      <React.Fragment ref={props.ref}>
        <Img style={{
          position: 'fixed',
          left: left,
          top: top,
          width: width,
          height: height,
        }}
             fluid={props.images[props.current]}
             imgStyle={{
               width: width,
               height: height,
             }}/>

        <div className={classes.root} onClick={props.onClose}>
          <div className={classes.top} />
          <div className={classes.outerCenter} >
            <div className={classes.left}>
              <IconButton onClick={onLeft} >
              <ArrowBackIosIcon fontSize="large" />
              </IconButton>
            </div>
            <div ref={imageAreaRef} className={classes.center}>

            </div>
            <div className={classes.right} style={{position:'relative'}}>
              <IconButton onClick={props.onClose} style={{
                top: 0,
                position: 'absolute',
              }}>
                <CloseIcon fontSize="large" />
              </IconButton>
              <IconButton onClick={onRight}>
              <ArrowForwardIosIcon fontSize="large" />
              </IconButton>
            </div>
          </div>
          <div className={classes.bottom} />
        </div>
      </React.Fragment>
  );
}

export default function Carousel(props) {
  return (
    <Modal open={props.open} BackdropProps={{style:{
        opacity: 0.9,
        backgroundColor: 'black',
      }}}>
      <InsideModal {...props}/>
    </Modal>
  );
}


import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Gallery from "react-photo-gallery"
import Header from "../components/header"
import Carousel from "../components/carousel"

const Photo = (props) => {
  const photo = props.photo
  const styles = {
    height: photo.height,
    width: photo.width,
    cursor: "pointer",
    margin: props.margin + "px"};
  return (
    <div key={photo.key} onClick={photo.onClick}>
      <Img style={styles}
           fixed={photo.src}
           alt={props.alt} />
    </div>
  )
}

export default function Portfolio() {
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState(0);

  const data = useStaticQuery(graphql`
    query {
      small: allFile(filter: {relativeDirectory: {eq: "portfolio"}}) {
        edges {
          node {
            childImageSharp{
              fixed(height: 310){
                ...GatsbyImageSharpFixed 
                aspectRatio
              }
            }
          }
        }
      }
      large: allFile(filter: {relativeDirectory: {eq: "portfolio"}}) {
        edges {
          node {
            childImageSharp{
              fluid (maxWidth: 3000){
                ...GatsbyImageSharpFluid
                aspectRatio
              }
            }
          }
        }
      }
    }
  `)

  const prepareSmall = (node, key) => {
    const img_data = node['node']['childImageSharp']['fixed'];
    const ratio = img_data['aspectRatio'];
    return {
      key: key.toString(),
      src: img_data,
      width: ratio,
      height: 1,
      onClick: () => {
        setCurrent(key);
        setOpen(true);
      },
    };
  }
  const smallPhotos = data['small']['edges'].map((node, index) => prepareSmall(node, index));

  const prepareLarge = (node, key) => {
    return node['node']['childImageSharp']['fluid'];
  }
  const largePhotos = data['large']['edges'].map((node, index) => prepareLarge(node, index));

  const margin = 10



  const updateCurrentSlide = (i) => {
    setCurrent(i);
  }
  const onClose = () => {
    setOpen(false);
  }

  return(
    <div style={{margin:margin}}>
      <Header/>
      <Gallery photos={smallPhotos} renderImage={Photo} targetRowHeight={300} margin={margin}/>
      <Carousel open={open} current={current} images={largePhotos} onLeft={updateCurrentSlide} onRight={updateCurrentSlide} onClose={onClose}/>
    </div>
  );
}
